<template>
  <DefultTempalte
    @reRenderChart="renderChart"
    v-loading.lock="loadingData"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="box-card">
      <el-row :gutter="20" type="flex" align="middle">
        <el-col :span="12" :offset="0">
          <h1>ภาพรวมโครงการ</h1>
        </el-col>
        <!-- <el-col :span="12" :offset="0" class="text-right">
          <el-button
            id="btnExportCSV"
            type="primary"
            @click="createLinkDownload"
            :loading="loadingExport"
          >
            Export</el-button
          >
        </el-col> -->
      </el-row>
      <el-card shadow="never">
        <div class="set-form">
          <el-form :model="filters" ref="ruleForm" label-position="top">
            <el-row :gutter="20">
              <el-col :span="12" :lg="4">
                <el-form-item label="ปี" class="padding-less">
                  <el-select
                    id="filtersYear"
                    @change="getDashboard()"
                    v-model="filters.year"
                    clearable
                    placeholder="ปีทั้งหมด"
                  >
                    <el-option
                      v-for="(item, index) in yearAll"
                      :key="index"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" :lg="5">
                <el-form-item label="ขนาดธุรกิจ" class="padding-less">
                  <el-select
                    id="filtersBusinessSize"
                    @change="getDashboard()"
                    v-model="filters.businessSize"
                    clearable
                    placeholder="ขนาดธุกิจทั้งหมด"
                  >
                    <el-option label="ธุรกิจขนาดเล็ก (S)" value="S">
                    </el-option>
                    <el-option label="ธุรกิจขนาดกลาง (M)" value="M">
                    </el-option>
                    <el-option label="ธุรกิจขนาดใหญ่ (L)" value="L">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" :lg="5">
                <el-form-item label="ประเภทอุตสาหกรรม" class="padding-less">
                  <el-select
                    id="filtersCateIndustryType"
                    @change="getDashboard(), (filters.industryType = '')"
                    v-model="filters.cateIndustryType"
                    clearable
                    filterable
                    placeholder="อุตสาหกรรมทั้งหมด"
                  >
                    <el-option
                      v-for="item in industryType"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" :lg="5">
                <el-form-item
                  label="หน่วยงานที่เป็นสมาชิก"
                  class="padding-less"
                >
                  <el-select
                    id="filtersMemberOf"
                    @change="getDashboard(), (filters.industryType = '')"
                    v-model="filters.memberOf"
                    multiple
                    filterable
                    default-first-option
                    popper-class="custom-select"
                    placeholder="สมาชิกของหน่วยงานทั้งหมด"
                  >
                    <el-option
                      v-for="item in memberOfOrganize"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                    >
                      <div class="is-flex ai-center">
                        <div class="circle-select"></div>
                        {{ item.label }}
                      </div>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" :lg="5">
                <el-form-item label="ค้นหาชื่อโครงการ" class="padding-less">
                  <el-input
                    id="filtersSearch"
                    placeholder="พิมพ์คำค้นหาชื่อโครงการ"
                    v-model="filters.search"
                    @input="getDashboard()"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-card>
      <el-row :gutter="20" type="flex" align="middle">
        <el-col :span="12" :offset="0">
          <h1>ภาพรวมข้อมูลโครงการ</h1>
        </el-col>
      </el-row>
      <div
        v-loading="loading && !loadingData"
        element-loading-text="กำลังโหลดข้อมูล..."
        element-loading-spinner="el-icon-loading"
      >
        <el-row :gutter="20" v-if="!loading">
          <el-col :span="24">
            <el-row :gutter="20" class="row-custom-dashboard">
              <!-- <el-col :span="24" :md="12" class="mg-t-5">
              <div class="bg-white pd-y-5 pd-x-6">
                <el-row :gutter="20">
                  <el-col :span="24">
                    <h2 class="mg-0">นำเสนอ</h2>
                    <GroupPie :dataFetch="dataPie" :color="colorChart" />
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :span="24" :md="12" class="mg-t-5">
              <div class="bg-white pd-y-5 pd-x-6">
                <el-row :gutter="20">
                  <el-col :span="24">
                    <h2 class="mg-0">สนใจ</h2>
                    <GroupPie :dataFetch="dataPie1" :color="colorChart" />
                  </el-col>
                </el-row>
              </div>
            </el-col> -->
              <el-col :span="24" :md="12" class="mg-t-5">
                <div class="bg-white pd-y-5 pd-x-6">
                  <el-row :gutter="20" class="row-custom-dashboard in-side">
                    <el-col :span="24">
                      <h2 class="mg-0">นำเสนอ</h2>
                      <GroupPie
                        :dataFetch="dataOffer"
                        :color="colorChart"
                        :fetchChart="render"
                        :typePage="``"
                        v-if="dataOffer.length > 0"
                      />
                      <div v-else class="nodata">
                        <h2 class="mg-0 text-center">ไม่มีข้อมูลนำเสนอ</h2>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
              <el-col :span="24" :md="12" class="mg-t-5">
                <div class="bg-white pd-y-5 pd-x-6">
                  <el-row :gutter="20" class="row-custom-dashboard in-side">
                    <el-col :span="24">
                      <h2 class="mg-0">สนใจ</h2>
                      <GroupPie
                        :dataFetch="dataInterested"
                        :color="colorChart"
                        :fetchChart="render"
                        :typePage="``"
                        v-if="dataInterested.length > 0"
                      />
                      <div v-else class="nodata">
                        <h2 class="mg-0 text-center">ไม่มีข้อมูลสนใจ</h2>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </div>
  </DefultTempalte>
</template>
<script>
import BarCharts from "@/charts/BarCharts";
import DefultTempalte from "@/template/DefaultTemplate";
import GroupPie from "@/components/dashboard/GroupPie";
import { HTTP } from "@/service/axios";
import { colorChart } from "@/colors/color.json";
import moment from "moment";

export default {
  components: {
    DefultTempalte,
    BarCharts,
    GroupPie,
  },
  computed: {
    memberOfOrganize() {
      return this.$store.state.memberOfOrganize;
    },
    industryType() {
      return this.$store.state.industryType;
    },
    yearAll() {
      let year = new Date().getFullYear() + 543;
      let yearStart = 2564;
      let returnYear = [];
      for (let i = 0; i < year + 1 - yearStart; i++) {
        returnYear.push((year - i).toString());
      }

      let month = Number(moment().format("M"));
      if (month == 12) {
        returnYear.unshift((year + 1).toString());
      }
      return returnYear;
    },
    _token() {
      return this.$store.state._token;
    },
  },
  mounted() {
    this.$store.commit("SET_PAGE_ACTIVE", "dashboard-project");
    this.criterionIdActive();
  },
  data() {
    return {
      render: false,

      urlBase: process.env.VUE_APP_BASE_URL,
      loadingExport: false,
      loading: true,
      colorChart: colorChart,
      filters: {
        year: "",
        businessSize: "",
        cateIndustryType: "",
        memberOf: [],
        search: "",
      },
      dataDashboard: [],
      dataOffer: [],
      dataInterested: [],
      criterionId: "",
      loadingData: true,
    };
  },
  methods: {
    async createLinkDownload() {
      let res = await this.exportDashboard();
      if (res.data.success) {
        var file_path = this.urlBase + "system/download/" + res.data.filename;
        var a = document.createElement("A");
        a.href = file_path;
        a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        this.$message({
          message: "Export ข้อมูลสำเร็จ",
          type: "success",
          duration: 4000,
        });
      } else {
        this.$message({
          message: "Export ไม่ข้อมูลสำเร็จ",
          type: "error",
          duration: 4000,
        });
      }
    },
    async exportDashboard() {
      this.loadingExport = true;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.get(`dashboard/export/project`)
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else {
              this.alertCatchError(e);
            }
          }
          return {
            data: {
              data: e,
              success: false,
            },
          };
        })
        .finally(() => {
          this.loadingExport = false;
        });
      return res;
    },
    async criterionIdActive() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`criterion/get/active`)
        .then(async (res) => {
          if (res.data.success) {
            this.criterionId = await res.data.obj._id;
            await this.getDashboard();
          } else {
            this.loading = false;
            this.loadingData = false;
            this.dataDashboard = [];
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    renderChart() {
      this.render = !this.render;
    },
    // criterion/get/active
    async getDashboard() {
      let obj = {
        year: this.filters.year != "" ? this.filters.year : 0,
        businessSize: this.filters.businessSize,
        cateIndustryType: this.filters.cateIndustryType.toString(),
        memberOf: this.filters.memberOf,
        search: this.filters.search,
        criterionId: await this.criterionId,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.post(`dashboard/project`, obj)
        .then(async (res) => {
          if (res.data.success) {
            this.loading = false;
            this.loadingData = false;

            this.dataDashboard = res.data;
            await this.setDataOffers(res.data);
            await this.setDataInterest(res.data);

            this.renderChart();
          } else {
            this.loading = false;
            this.loadingData = false;

            this.dataDashboard = {};
          }
          return res;
        })
        .catch((e) => {
          this.loading = false;
          this.loadingData = false;

          this.dataDashboard = {};

          if (typeof e.response != "undefined") {
            if (e.response.status != 502) {
              if (e.response.data.status == 401) {
                this.checkAuth();
              } else if (
                e.response.data.status == 422 &&
                e.response.data.data == '"criterionId" is required'
              ) {
                this.$message({
                  message:
                    "กรุณาสร้างเกณฑ์พิจารณาจัดเกรดสินเชื่อ และ Active เกณฑ์",
                  type: "error",
                  duration: 5000,
                });
              } else if (e.response.data.status == 502) {
                console.log("Bad Gateway");
              } else {
                this.alertCatchError(e);
              }
            }
          }
          return {
            data: {
              data: e,
              success: false,
            },
          };
        })
        .finally(() => {
          this.loading = false;
        });
      return res;
    },
    async setDataOffers(data) {
      const { offersLen, projectOffers } = data;
      if (typeof offersLen != "undefined" && typeof projectOffers == "object") {
        this.dataOffer = await projectOffers.map((row) => ({
          ...row,
          percent: this.totalPercent(row.value, offersLen),
        }));
      }
    },
    async setDataInterest(data) {
      let { interested, interestedLen } = data;
      if (!!interested && interestedLen != null && interestedLen != undefined) {
        this.dataInterested = await interested.map((row) => ({
          ...row,
          percent: this.totalPercent(row.value, interestedLen),
        }));
      }
    },
    searchNameProject() {
      let debounce = null;
      clearTimeout(debounce);
      debounce = setTimeout(() => {
        this.getDashboard();
      }, 600);
    },
  },
};
</script>
